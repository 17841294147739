<template>
 <div style='width: 100%; height: 100%; position: relative;'>
   
     <div class='transition' :style='getControlStyle()'>
    	<!-- CONTROLS -->
    	    
		    <div style='position: relative; display: flex; width: 100%; height: calc(100vh - 190px);'>
		        <div style='float: top;'>
			        <div style='vertical-align: middle; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'>PRICING 1.0</div>
			        <br/>
					<span class='SVcontrolLabel'>Channel/Station:</span><br/>
					
								
					<GSelectSimple :options="stations" style='width:100%' v-model="stationId" @changed='stationChanged'/>
			        <div style='font-size: 9pt;'>Period</div>
			        <div style='display: inline-flex; width: 45%;'>
				    <InputDatePick :auto="false" 
	                            @change="dateChanged" v-model="fromDate"/>  
	                            </div>
	                            -
	                <div style='display: inline-flex; width: 45%;'>
	                <InputDatePick :auto="false" @change="dateChanged" v
	                            v-model="untilDate"/>     
	                 </div>  
					 <!--
					  <div style='font-size: 9pt;'>Reference Date</div>
 			         <div style='display: inline-flex; width: 45%;'>
						<InputDatePick :auto="false" @change="dateChanged" v
					                   v-model="dateRef"/> 
 	                </div>
					<div style='display: inline-flex; width: 45%;'>
										{{dateRef}}
						                </div>
										
					-->
					<div class="dontWrap">
						<div class="rt" style="display: inline-block; padding-top: 10pt; font-size: 9pt; width:48%">
						Rate types
						</div>
					<div style="display: inline; min-width:50%; max-width:50%">
						<button  v-for="(rt, ri) in types" :key="'rt'+ri" :title='"select rate-types"' @click="selectType(rt)"
						         :class="'button myButton myButtonCalc' + ((options.selectedTypes.find( p=>p === rt))?' myBNActive':'')" 
								 :style="getAppStyle()">
							TG-{{rt}}
						</button> 
					</div>
					</div> 					 
	                
					
					<br/>
					<div class="dontWrap"><div style="display: inline-block; font-size: 9pt; width:48%">Target CPM</div> <div style="display: inline-block; max-width:50%"><InputMoney v-model="pricingHeader.targetCPM"/></div></div>
					<div class="dontWrap"><div style="display: inline-block; font-size: 9pt; width:48%">Tolerance</div> <div style="display: inline-block; max-width:50%"><InputDouble v-model="pricingHeader.tolerance"/></div></div>
					<div class="dontWrap"><div style="display: inline-block; font-size: 9pt; width:48%">Max PG step</div> <div style="display: inline-block; max-width:50%"><InputInt v-model="pricingHeader.maxPGStep"/></div></div>
					
					
					<button :disabled="!pricingHeader.targetCPM" :title='"calculate pricegroups"' class='button myButton myButtonLock' :style="getAppStyle()" @click="lockData( false)"><mdicon :size="18" name="lock-open-variant-outline" /></button> 
					<button :disabled="!pricingHeader.targetCPM" :title='"calculate pricegroups"' class='button myButton myButtonLock' :style="getAppStyle()" @click="lockData( true)"><mdicon :size="18" name="lock" /></button> 
										
					<button :disabled="!pricingHeader.targetCPM" :title='"calculate pricegroups"' class='button myButton myButtonCalc' :style="getAppStyle()" @click="calcData()"><mdicon :size="18" name="calculator" /></button> 
					<button  :title='"save setup"' class='button myButton myButtonCalc' :style="getAppStyle()" @click="saveHeader()"><mdicon :size="18" name="content-save-settings" /></button> 
																							
					<br/>
					
	                <br/>


	                <button :title='"reload screen"' class='button myButton' :style="getAppStyle()" @click="getData()"><mdicon :size="18" name="reload" /></button> 
					<button :title='"create excel report"' class='button myButton' :style="getAppStyle()" @click="createExcel()"><mdicon :size="18" name="microsoft-excel" /></button> 
										
												
					<br/>
					<span style='font-size: 8pt'>on click:</span>
					<br/>
					<button :title="'Open/Edit '+tr('Placement')" :class="'button mySelectButton '+(selectedAction===0?'selected':'')"  
				        @click="selectedAction=0"><mdicon :size="16" name="cursor-default-click-outline" /> <span style="padding-left: 5pt;">Open break</span></button> 			
					<button :title="Open/Edit/Budget" :class="'button mySelectButton '+(selectedAction===1?'selected':'')"  
						@click="selectedAction=1"><mdicon :size="16" name="cursor-default-click-outline" /> <span style="padding-left: 5pt;">Check structure break/bookings</span></button> 			
					<br/><br/>
					<button @click="showColorPicker($event, 'up')" title="set color for higher prices"
						class='button myButton' :style="'font-size: 7pt; padding: 2pt; width: 24pt; color: '+autoFG(options.up)+';background-color: '+options.up">UP</button>
					<button @click="showColorPicker($event, 'down')" title="set color for lower prices"
						class='button myButton' :style="'font-size: 7pt; padding: 2pt; width: 24pt; color: '+autoFG(options.down)+'; background-color: '+options.down">Down</button>						
					
				</div>
				
		    	<div style='position:absolute; bottom:50px;'>
		    	<span class='SVcontrolLabel'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
		    	</div>
		    	
					
		    	
		    </div> <!-- CONTROLS -->
    </div>
    
    <!-- MAIN -->
	<div v-if="data" class='transition' style='float: left; width: 78%;' @dragover.prevent="openUpload($event)"> 
	    <!-- HEADER -->
		<Upload v-if="uploadId" :activeDate="dateRef" :stationId="stationId"  @closeUpload="uploadId=0; getData();">
						</Upload>
		<div v-else style='width: calc(100%); height: calc(100vh - 110px);'  >
		    <!-- TABLE -->
		    <div v-if="ready && data" style='width: calc(100%); height: calc(100vh - 110px);'  >
				
				<button v-for="(wd,si) in data.weekdays" :key="'stat'+si" 
				    :class="'button myTab'+(activeWeekday==wd?' myTabActive':'')" :active="activeWeekday==wd" @click="activate( wd)">
					{{wd.toUpperCase()}}
				</button>
				<button  
				    :class="'button myTab'+(activeWeekday=='TOTALS'?' myTabActive':'')" :active="activeWeekday=='TOTALS'" @click="activate( 'TOTALS')">
					&Sigma; Weekdays
				</button>
			
				<div v-if="activeWeekday=='TOTALS'" style='float: top; width: 100%; height: 18pt; overflow-y: scroll;'>
					<div style='float: top;'>
					    <div v-for="(col,idx) in selectedColumns" :key="'ColH_'+idx" class='floatLeft tableHeader' :style='getColumnStyle(col, idx)'
							@click='setOrderBy(col)' @contextmenu='showColumnSelect($event)'
							>
					       <div class='header' 
					            :style="isDarkMode()?'background-color: #666; color: #fff;':'background-color: #ddd; color: #000;'">
					       		<div class='ltd' :title="col.name"> {{col.name}}<sub>{{getOrderBy(col)}}</sub></div>
					       	</div>
					    </div>
										   
					</div>  <!-- float: top --> 
				</div>			
		    	<div v-else style='float: top; width: 100%; height: 38pt; overflow-y: scroll;'>
				    <div style='float: top;'>
					    <div v-for="(col,idx) in selectedColumns" :key="'ColH_'+idx" class='floatLeft tableHeader' :style='getColumnStyle(col, idx)'
							@click='setOrderBy(col)' @contextmenu='showColumnSelect($event)'
							>
					       <div class='header' 
					            :style="isDarkMode()?'background-color: #666; color: #fff;':'background-color: #ddd; color: #000;'">
					       		<div class='ltd' :title="col.name"> {{col.name}}<sub>{{getOrderBy(col)}}</sub></div>
					       	</div>
					    </div>
					   
				    </div>  <!-- float: top -->   
					<div style='float: top;'>
							<div v-for="(col,idx2) in selectedColumns" :key="'CHS_'+idx+'_'+idx2" :class="'floatLeft'"  :style='getColumnStyle(col, idx2)'>
																										    	        
								<div :class="'reqDetail dontWrap '+ col.class" >
						            <div v-if="col.type==='icon'" :title="col.tooltip" style='width: 100%; height: 100%; padding-top: 0pt !important;'>
						       			<span>
											<button v-if="false"></button>
						       			</span>
						       		</div>
									<div v-else-if="col.show==='price30New' || col.show==='price30Delta'" :class="'reqDetail2 '+ col.class" :style='getColumnDetailStyleNPG(col, weekdayTotals[activeWeekday])' >
						       			{{print( weekdayTotals[activeWeekday], col)}}
						       		</div>
						       		<div v-else  :class="'reqDetail2 '+ col.class" :style='getColumnDetailStyle(col, weekdayTotals[activeWeekday])' >
						       			{{print( weekdayTotals[activeWeekday], col)}}
						       		</div>
						       	</div>
							</div>
						</div>	
				</div> <!-- HEADER --> 
		    <!-- TABLE -->
			<div v-if="activeWeekday=='TOTALS' && ready" style='width: 100%; height: calc(80vH) !important; overflow-y: scroll;' >
		    	<div   style='float: top; width: 100%;height: 100%;' >

					
		    	    <div v-for="(line,idx) in weekdayTotals" 
		    	         class='reqLine' :key="'TOT_'+idx" :ref="'TOT_'+idx" :id="'TOT_'+idx"
						 
					
		    	         @mouseenter="hoverLine('TOT_'+idx)"
		    	         @mouseleave="leaveLine('TOT_'+idx)"
						 
		    	        >
						
		    	        <div v-for="(col,idx2) in selectedColumns" :key="'CS_'+idx+'_'+idx2" :class="'floatLeft'"  :style='getColumnStyle(col, idx2)'>
							<div :class="'reqDetail dontWrap '+ col.class" >
							<div v-if="col.show==='price30New' || col.show==='price30Delta'"  :class="'reqDetail2 '+ col.class"
							:style='getColumnDetailStyleNPG(col, line)' >
				       			{{print( line, col)}}
				       		</div>
		    	            <div v-else :class="'reqDetail2 '+ col.class" :style='getColumnDetailStyle(col, line)'>
					            {{print( line, col)}}
					       	</div>
							</div>
					     </div> 
						 	
			    	        
		    	    </div>
		    		
		    	</div> 
		    </div>  <!-- TABLE -->					
		    <div v-else-if="ready" style='width: 100%; height: calc(78vH) !important; overflow-y: scroll;' >
		    	<div   style='float: top; width: 100%;height: 100%;' >

					
		    	    <div v-for="(line,idx) in doOrderBy(limit(data.grid, searchTerm))" 
		    	         class='reqLine' :key="'DAT_'+idx" :ref="'DAT_'+idx" :id="'DAT_'+idx"
						 
						 @contextmenu="ctxMenu($event, line, idx)"
		    	         @mouseenter="hoverLine('DAT_'+idx)"
		    	         @mouseleave="leaveLine('DAT_'+idx)"
						 
		    	        >
						
		    	        <div v-for="(col,idx2) in selectedColumns" :key="'CS_'+idx+'_'+idx2" :class="'floatLeft'"  :style='getColumnStyle(col, idx2)'>
		    	            <div :class="'reqDetail dontWrap '+ col.class" >
					            <div v-if="col.type==='icon'" :title="col.tooltip" style='width: 100%; height: 100%; padding-top: 0pt !important;'>
					       			<span>
										<button :style="getAppStyle()" :disabled="line.newPricegroup == line.pricegroup" @click="resetPG(line)" class="button myButtonSmall"><mdicon size=18 name="restore"/></button>
										<button :style="getAppStyle()" @click="check(line)" class="button myButtonSmall"><mdicon size=18 name="check"/></button>
																				
										<button :style="getAppStyle()" :disabled="line.newPricegroup == line.pricegroup" @click="apply(line)" class="button myButtonSmall"><mdicon size=18 name="content-save-move"/></button>
																				
										
									</span>
					       		</div>
								<div v-else-if="col.show==='newPricegroup'" :title="line.display[col.id]" :class="' '+ col.class" :style='getColumnDetailStyle(col, line)' >
					       			<select v-model="line.newPricegroup" @change="newPGChange(line)" style="border: none; height: 100%; width:100%">
										<option v-for="(r,ri) in ratecards" :key="'rx'+ri" :value="r.id" :selected="r.id==line.newPricegroup">{{r.label}}</option>
									</select>
					       		</div>
								<div v-else-if="col.show==='lockNewPG'" :class="' '+ col.class" :style='getColumnDetailStyle(col, line)' >
									<button :style="getAppStyle()" title="unlock new pricegrouo for changes" v-if="line.lockNewPG" @click="lockLine(line, false)" class="button myButtonSmall"><mdicon size=18 name="lock"/></button>
									<button :style="getAppStyle()" title="lock new pricegrouo for changes" v-else @click="lockLine(line, true)" class="button myButtonSmall"><mdicon size=18 name="lock-open-variant-outline"/></button>
								</div>
								<div v-else-if="col.show==='price30New' || col.show==='price30Delta'" :title="line.display[col.id]" :class="'reqDetail2 '+ col.class" :style='getColumnDetailStyleNPG(col, line)' @click="actionOnClick( line)" >
					       			{{line.display[col.id]}}
					       		</div>
					       		<div v-else :title="line.display[col.id]" :class="'reqDetail2 '+ col.class" :style='getColumnDetailStyle(col, line)' @click="actionOnClick( line)" >
					       			{{line.display[col.id]}}
					       		</div>
					       	</div>
					     </div> 
						 	
			    	        
		    	    </div>
		    		
		    	</div> 
		    </div>  <!-- TABLE -->
		</div>
	</div>  <!-- TABLE -->
		
    	
    </div>  <!-- MAIN -->
    <ContextMenu ref="menu" :offsetY="-100">
      <template v-if="contextData && contextData.data"  slot-scope="{ contextData }">
        
		<ContextMenuItem v-if="contextData.data.id" @clicked="$refs.menu.close(); openModule('Placement', contextData.data.id); ">
        	Edit {{contextData.data.placementName}}
        </ContextMenuItem>
		<ContextMenuSep/>
		<ContextMenuItem @clicked="$refs.menu.close(); openBudgetView(contextData.data); ">
        	View monthly budgets
        </ContextMenuItem>
	
      </template>    
	</ContextMenu>
	<PDFViewer name='pdfView' ref='pdfView' />
    <GConfirm ref='confirm'/>
	<InfoPopUp :time=infoTime :infoType=asText :info=showAsText></InfoPopUp>
    <GFWEOpenEditor ref='editor'></GFWEOpenEditor>
	<ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
	<RevenueView ref='revenueView' 
	       :currentLine = currentLine
	        @placement="p=> save(p)"
	        @close="closeBreakView()">
	</RevenueView>
	<ColumnSelector v-if="ready" v-show='showColumnSelector' 
		        :availableColumns=availableColumns
		        :selected=selected
		 		:byId="true"
				@selectedColumns=setSelectedColumns
		        @close="showColumnSelector=false">
	        
	    </ColumnSelector>
	<vue-modal-2 :darkMode="false" style="z-index: 9999"
		       :headerOptions="{title: 'set color for '+optionColor}" 
		       :footerOptions="{ btn1: 'Cancel', btn2: 'Set', btn1OnClick:closeColorPicker, btn2OnClick:closeColorPicker, disableBtn2:false}"
		       name="modal-selectColor"  @on-close="closeColorPicker">
				<div style='padding-left: 10pt; height: calc(50vH)!important; overflow-y: auto; '>
		        <color-panel v-model="options[optionColor]" ></color-panel>
				</div>
		    </vue-modal-2>
 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, opsAPI, reportAPI, dealReportAPI, clientReportAPI, pricingAPI, placementServiceAPI, setReload, myLocale, showError, formatNumber} from '@/variables.js';
import { setGoBack } from '@/breadCrumb.js';
import { tr } from '@/translate.js';
import { isoDate } from '@/basicTimeFN.js';
import { forceFileDownload} from '@/fileUtil.js';
import { getAppStyle, isDarkMode, initAppMode, getFG, getBG, getBGColor, autoFG } from '@/AppStyle.js';
import { printTimeMMSS, printTimeHHMM } from '@/basicTimeFN.js';
import InputTime from '@/components/inputElements/InputTime2';
import InputText from '@/components/inputElements/InputText';
import InputMoney from '@/components/inputElements/InputMoney';
import InputDouble from '@/components/inputElements/InputDouble';
import InputInt from '@/components/inputElements/InputInt';
import ColumnSelector from '@/components/ColumnSelector';
import Switch from '@/components/Switch';
import InfoPopUp from '@/components/InfoPopUp';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import ContextMenuLabel from '@/components/ContextMenuLabel';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GridBreakView from '@/components/GridBreakView';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import Upload from '@/components/pricing/Upload'; 
import GSelectSimple from '@/components/GSelectSimple'; 
import ProgressBar from '@/components/ProgressBar';
import PDFViewer from '@/components/PDFViewer';
import RevenueView from '@/components/LineItemSiteRevenue';
import GConfirm from '@/components/GConfirm';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { format } from '@/Numbers.js';
var momentTZ = require('moment-timezone');

var timers = [];
var lastMarked = "-";
import JQuery from "jquery";
let $ = JQuery;
export default {
  name: 'NEXT_CopyENTRY_View',
  components : {
   'app-switch': Switch, InputDatePick, PDFViewer, RevenueView, GSelectSimple, Upload,InfoPopUp, ColumnSelector,
   ProgressBar, GConfirm, ContextMenu, ContextMenuItem, ContextMenuSep, InputMoney, InputInt, InputDouble
  },
  data () {
    return {
      data: {},
	  types: [],
      fromDate: new Date(),
      untilDate: new Date(),
      showProgressBar: false,
	  showColumnSelector: false,
      searchTerm: "",
	  stationId: 0,
	  dateRef: "",
	  stations: [],
	  ratecards: [],
	  activeWeekday: "",
	  pricingHeader: {},
      search: "",
      dealId: 0,
	  selectedAction: 0,
      dealData: {},
      dataSave: {},
	  weekdayTotals: {},
      pbTitle: "",
	  pbCall: "",
	  pbLock: false,
      pbAction: "",
	  uploadId: 0,
	  pbId: 0,
	  pbStatusId: 0,
      controlsRight: false,
      ready: false,
	  currentLine: {},
      fontSize: 11,
      widthCalculated: 800,
	  asText: "",
	  tg1: {},
	  tg2: {},
      showAsText: "",
      infoTime: 0, 
	  options: {up: "#fcc", down: "#cfc", selectedTypes: []},
      tr, getAppStyle, getBGColor, format, isDarkMode, autoFG,
      orderBy: [],
      p: 1,
      availableColumns: [
	        {id: 10, name: "Name", show: "name", type: 'txt', disabled: true, class:'left', w: 8},
			{id: 20, name: "Time", show: "time", type: 'time', disabled: true, class:'left', w: 8},
			{id: 30, name: "Dur", show: "durationInSeconds", type: 'txt', disabled: true, class:'centered', w: 2},
			{id: 40, name: "Ø <TG1>", pattern: "TG1", show: "avgReachTG1", type: 'reach', disabled: true, class:'right', w: 4},
			{id: 50, name: "PG", show: "pricegroup", type: 'txt', disabled: true, class:'centered', w: 2},
			
			
			{id: 60, name: "Price", show: "price30", type: 'txt', disabled: true, class:'right', w: 4},
			{id: 70, name: "CPM", show: "cpm", type: 'txt', disabled: true, class:'right', w: 4},
			{id: 80, name: "PGN", show: "newPricegroup", type: 'boolean', disabled: true, class:'centered', w: 5},
			{id: 90, name: "Price New", show: "price30New", type: 'txt', disabled: true, class:'right', w: 4},
			{id: 91, name: "Price Δ", show: "price30Delta", type: 'txt', disabled: true, class:'right', w: 4},
			{id: 92, name: "Lock", show: "lockNewPG", type: 'boolean', disabled: true, class:'centered', w: 2},
			{id: 100, name: "CPM Ø", show: "cpmNew", type: 'txt', disabled: true, class:'right', w: 4},
			{id: 101, name: "CPM Δ", show: "cpmDelta", type: 'txt', disabled: true, class:'right', w: 4},
			{id: 102, name: "CPM Δ T", show: "cpmTargetDelta", type: 'txt', disabled: true, class:'right', w: 4},
			{id: 110, name: "Ø <TG2>", pattern: "TG2", show: "avgReachTG2", type: 'reach', disabled: true, class:'right', w: 4},
			
			{id: 120, name: "From", show: "fromDate", type: 'txt', disabled: true, class:'centered', w: 4},
			{id: 130, name: "To", show: "untilDate", type: 'txt', disabled: true, class:'centered', w: 4},
			{id: 200, name: "Action", show: "action", type: 'icon', disabled: true, class:'centered', w: 6}
      ],
      selectedIN: "",
      selectedDefault: [10,20,30,40,50,60,70,80,90,91,92,100,101,102,110,120,130,200],
      selectedSave: [10,20,30,40,50,60,70,80,90,91,92,100,101,102,110,120,130,200],
      selectedDefaultD: [10, 20, 30, 40, 50, 92, 80, 60, 90, 70, 91, 100, 101, 102, 110, 120, 130, 200],
      selected: [],
      selectedColumns: [
	        
      ]
    }
  },
  methods: {
	dateChanged()
	{
	    	this.getData();
	},
	stationChanged( station)
	{
		//
	},
	showColorPicker( event, color)
	{
		this.optionColor = color;
	    this.$vm2.open('modal-selectColor');
		
	},
	closeColorPicker()
	{
		this.$vm2.close('modal-selectColor');
	},
    action( what, myId, api)
    {
   		this[what](myId, api)
    },
	activate( stat )
	{
		this.activeWeekday = stat;
		
	},
	selectType( rt )
	{
		if ( !this.options.selectedTypes.find( p=>p === rt))
		{
			this.options.selectedTypes.push( rt)
		}
		else
		{
			this.options.selectedTypes = this.options.selectedTypes.filter( p=>p !== rt);
		}
		this.calcTotals();
		this.$forceUpdate();
	},
	showColumnSelect(event)
    {
        event.preventDefault();
    	this.showColumnSelector=true;
    },
	setSelectedColumns( sc ) { 
		this.selectedColumns = sc; 
		this.options.columns = this.selectedColumns.map(p=>p.id); 
		this.calcWidths( false ) 
	},
	resetPG(line )
	{
		if ( line.newPricegroup && line.newPricegroup != line.pricegroup)
		{
			line.newPricegroup = line.pricegroup;
			this.newPGChange(line )
		}
	},
	newPGChange(line )
	{
		let that = this;
		HTTP.post( pricingAPI+"/calcSingleBreak/"+sessionStorage.tenantId
		       				+"/"+sessionStorage.accessPointId
		       				+"/"+sessionStorage.unitId
		       				+"/"+sessionStorage.userId
							+"/"+that.stationId
							+"/"+line.id
							+"/"+line.newPricegroup
		       				+"/"+that.dateRef,
							that.pricingHeader
		       				
		       				)

				 .then( response => 
		         {
					//let x = that.data.grid.find( p=>p.id==line.id)
					//alert( JSON.stringify( response.data))
					line.newPricegroup = response.data.newPricegroup;
					line.price30New = response.data.price30New;
					line.price30Delta = response.data.price30Delta;
					line.cpmDelta = response.data.cpmDelta;
					line.cpmTargetDelta = response.data.cpmTargetDelta;
					line.cpmNew = response.data.cpmNew;
					line.lockNewPG = response.data.lockNewPG;
					that.calcTotals();
				}).catch(e => {
		    	    showError( that.$toast, "get excel data", e);
		    	    
		        });
	},
	saveHeader(  )
	{
		if ( !this.ready )
		{
			return;
		}
		let that = this;
		let req = {	 
							fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
							untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] 
				};
		if ( req.fromDateISO)
		{
			that.pricingHeader.fromDate = req.fromDateISO;
		}
		if ( req.untilDateISO)
		{
			that.pricingHeader.untilDate = req.untilDateISO;
		}
		that.pricingHeader.definitions = that.options;
		HTTP.post( pricingAPI+"/savePricingHeader/"+sessionStorage.tenantId
					+"/"+sessionStorage.accessPointId
       				+"/"+sessionStorage.unitId
       				+"/"+sessionStorage.userId
					+"/"+that.pricingHeader.mediaId
					+"/"+that.pricingHeader.fromDate
       				+"/"+that.pricingHeader.untilDate,
				that.pricingHeader
   				)

				 .then( response => 
		         {
					that.$toast.success("Settings saved", 'Ok', { timeout: 1000, position: "topRight" });
				}).catch(e => {
		    	    showError( that.$toast, "save settings", e);
		    	    
		        });
	},
	apply(line )
	{
		let that = this;
		this.$refs.confirm.confirm( {text: 'Apply changes on '+line.name, title: "Apply", button1: "Cancel", button2: "Apply"}).then(x => {
		
			let data = { line: line, header: that.pricingHeader}
			this.dateRef = momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0]; 
			
			HTTP.post( pricingAPI+"/applySingleBreak/"+sessionStorage.tenantId
			       				+"/"+sessionStorage.accessPointId
			       				+"/"+sessionStorage.unitId
			       				+"/"+sessionStorage.userId
								+"/"+that.stationId
								+"/"+line.id
								+"/"+line.newPricegroup
			       				+"/"+that.dateRef,
								data
			       				
			       				)
	
					 .then( response => 
			         {
						//let x = that.data.grid.find( p=>p.id==line.id)
						//alert( JSON.stringify( response.data))
						line.newPricegroup = response.data.newPricegroup;
						line.pricegroup = response.data.pricegroup;
						line.name = response.data.name;
						line.price30New = response.data.price30New;
						line.price30Delta = response.data.price30Delta;
						line.cpmDelta = response.data.cpmDelta;
						line.cpmTargetDelta = response.data.cpmTargetDelta;
						line.cpmNew = response.data.cpmNew;
						line.fromDate = response.data.fromDate;
						line.untilDate = response.data.untilDate;
						line.lockNewPG = response.data.lockNewPG;
						line.id = response.data.id;
						that.calcTotals();
					}).catch(e => {
			    	    showError( that.$toast, "get excel data", e);
			    	    
			        });
		});
	},
	check(line )
	{
		let that = this;
		let data = { line: line, header: that.pricingHeader}
		this.dateRef = momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0]; 
		
		HTTP.post( pricingAPI+"/checkSingleBreak/"+sessionStorage.tenantId
		       				+"/"+sessionStorage.accessPointId
		       				+"/"+sessionStorage.unitId
		       				+"/"+sessionStorage.userId
							+"/"+that.stationId
							+"/"+line.id
							+"/"+line.newPricegroup
		       				+"/"+that.dateRef,
							data
		       				
		       				)

				 .then( response => 
		         {
					//let x = that.data.grid.find( p=>p.id==line.id)
					//alert( JSON.stringify( response.data))
					that.showAsText = "<ul style='padding-left: 3ex; list-style-type: circle;'>";
		   			that.showAsText += "<li>Free value before: " + formatNumber(response.data.freeBefore)+"</li>";
					that.showAsText += "<li>Free value after: " + formatNumber(response.data.freeAfter)+"</li>";
					that.showAsText += "<li>Paid value before: " + formatNumber(response.data.grossBefore)+"</li>";
					that.showAsText += "<li>Paid value after: " + formatNumber(response.data.grossAfter)+"</li>";
					that.showAsText += "<li>Spots: " + response.data.count+"</li>";
		   			that.showAsText += "<li>"+tr('Placement')+"s: " + response.data.placements.length+ "</li>";
					for ( let pli in response.data.placements)
					{
		   				that.showAsText += "<li>"+response.data.placements[pli].name+"</li>";
		   			}
		   			that.showAsText += "</ul>";
		            //this.showAsText = JSON.stringify(response.data);
		            that.infoTime = new Date().getTime();
		            that.asText = line.name;
				}).catch(e => {
		    	    showError( that.$toast, "get excel data", e);
		    	    
		        });
	},
	lockLine(line, lock )
	{
		let that = this;
		
		HTTP.post( pricingAPI+"/lockSingleBreak/"+sessionStorage.tenantId
		       				+"/"+sessionStorage.accessPointId
		       				+"/"+sessionStorage.unitId
		       				+"/"+sessionStorage.userId
							+"/"+that.stationId
							+"/"+line.id
							+"/"+lock
		       				+"/"+that.dateRef,
							that.pricingHeader
		       				
		       				)

				 .then( response => 
		         {
					//let x = that.data.grid.find( p=>p.id==line.id)
					//alert( JSON.stringify( response.data))
					line.newPricegroup = response.data.newPricegroup;
					line.price30New = response.data.price30New;
					line.price30Delta = response.data.price30Delta;
					line.cpmDelta = response.data.cpmDelta;
					line.cpmTargetDelta = response.data.cpmTargetDelta;
					line.cpmNew = response.data.cpmNew;
					line.lockNewPG = response.data.lockNewPG;
					that.calcTotals();
				}).catch(e => {
		    	    showError( that.$toast, "lock", e);
		    	   
		        });
	},
	openUpload( evt)
	{
		
		if ( evt && evt.dataTransfer && evt.dataTransfer.types && evt.dataTransfer.types[0] === 'Files' )
		{
			
			if ( !this.uploadId)
			{
				this.uploadId=1
			}
		}
	},
	
	actionOnClick( line)
	{
		if ( this.selectedAction == 0 )
		{
			this.openModule( 'BreakGrid', line.id);
		}
		else if ( this.selectedAction == 1 )
		{
			this.check( line);
		}
		else if ( this.selectedAction == 2 )
		{
			this.downloadConf( line.id)
		}
		else if ( this.selectedAction >= 3 )
		{
			this.downloadSpotList( line.id, this.data.reports[this.selectedAction-3])
		}
	},
	openBudgetView( data)
	{
		this.currentLine = {type: 'PLACEMENT', sites: this.data.sites, placement: data.placement, info: data};
		this.$refs.revenueView.open( this.currentLine );
	},
	openModule(what, id)
	{
		this.$refs.editor.open( what, id);
	},
    show( line)
    {
    	alert( JSON.stringify( line.placementIds))
    },
    ctxMenu(event, data, idx) 
    { 
       event.preventDefault();
       this.$refs.menu.open(event, {data: data, row: idx} )
    },
	createExcel( ) {
    	this.pbAction = "createExcelInternal";
    	this.pbInfo = "Creating excel";
		this.pbCall = "createExcel4Pricing"
    	this.showProgressBar = true;
    },
	createExcelInternal(myId, api)
	{
		let that = this;
		let req = {	 
					fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
					untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] 
		};
		api.post( pricingAPI+"/"+this.pbCall+"/"+sessionStorage.tenantId
			+"/"+sessionStorage.accessPointId
			+"/"+sessionStorage.unitId
			+"/"+sessionStorage.userId
			+"/"+that.stationId
			+"/"+req.fromDateISO
			+"/"+req.untilDateISO
			+"/"+myId,
			that.pricingHeader
			)

		 .then( response => 
         {
			let downloadResponse = response.data;
          
            forceFileDownload( downloadResponse.file, downloadResponse.filename);
			that.showProgressBar = false;
         	//
            
         }).catch(e => {
        	    showError( that.$toast, "get excel data", e);
        	    that.showProgressBar = false;
               
            });
	},

	getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 21%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 21%; height: calc(100vh - 120px); padding: 2ex;"
    },
    getColumnStyle(col, idx)
    {
    	let wTotal = this.widthCalculated;
        return "width: " + (parseInt(col.w)/wTotal*100) +"%; background-color: "+getBG()+";color: "+getFG();
    },
    getColumnDetailStyle(col, line)
    {
     	return "width: 100%; height: 100%; font-size:"+this.fontSize+"px;";
    },
	getColumnDetailStyleNPG(col, line)
    {
		if ( line.price30Delta )
		{
			if ( line.price30Delta > 0 )
			{
				return "width: 100%; font-weight: bold; height: 100%; font-size:"+this.fontSize+"px; color: "+autoFG(this.options.up)+";background-color: "+this.options.up;
			}
			if ( line.price30Delta < 0 )
			{
				return "width: 100%; font-weight: bold; height: 100%; font-size:"+this.fontSize+"px; color: "+autoFG(this.options.down)+";background-color: "+this.options.down;
			}
		}
    	return "width: 100%; height: 100%; font-size:"+this.fontSize+"px;";
    },
    doOrderBy(data)
    {
       let that = this;
       
    	data.sort(function(a,b) {
	     	for ( var i in that.orderBy)
	     	{
	     		let iCol = that.orderBy[i];
	     		let v1 = a[ iCol.show ];
	     		let v2 = b[ iCol.show ];
	     		
	     		if ( ! v2 || v1 > v2 )
	     		{
	     			return iCol.sorting;
	     		}
	     		if ( ! v1 || v1 < v2 )
	     		{
	     			return -iCol.sorting;
	     		}
	     	}
	     	return a.index - b.index;
	     });
		return data;
    },
    doSearch( force) {
	  	if ( force || (this.searchTerm))
		{
	    	this.search = this.searchTerm ? this.searchTerm.toLowerCase(): "*";
	    	this.limit( this.data);
		}
		
	  },
	  applySearch( data)
	    {
	    	let filtered = [];
			this.search = this.searchTerm ? this.searchTerm.toLowerCase(): "*";
	    	for (let di in data)
	    	{
	    		let line = data[di];
	    		line.display = [];
				
	    		let match = !this.search || this.search==="*";
	    		for (let ci in this.selectedColumns)
	    		{
	    			let col = this.selectedColumns[ci];
	    			let displayValue = this.print( line, col);
	    			line.display[ col.id ] = displayValue;
	    			if ( displayValue )
	    			{
						//alert( displayValue.toString().toLowerCase() +"//"+this.searchTerm+displayValue.toString().toLowerCase().includes(this.search))
		    			match = (match || (displayValue && displayValue.toString().toLowerCase().includes(this.search)));
	    			}
	    		}
	    		if ( match )
	    		{
	    			filtered.push( line);
	    		}
	    	}
	    	return filtered;
	    },
    limit( data)
    {
        
        let tmp = data.filter( p=>p.weekdayValue==this.activeWeekday)
		tmp = tmp.filter( p=>this.options.selectedTypes.find(x=>x===p.rateType))
        tmp = this.applySearch( tmp);
		
    	let array = this.doOrderBy( tmp);
    	if ( this.newLine )
    	{
    		array.push( this.newLine);
    	}
    	
    	return array;
    },
    hoverLine(line)
    {
    	
    	if ( isDarkMode())
    	{
    		$('#'+line).children().addClass('reqLineBGDM');
    	}
    	else
    	{
    		$('#'+line).children().addClass('reqLineBG');
    	}
    },
    leaveLine(line)
    {
    	if ( isDarkMode())
    	{
    		$('#'+line).children().removeClass('reqLineBGDM');
    	}
    	else
    	{
    		$('#'+line).children().removeClass('reqLineBG');
    	}
    },
    print( line, col)
    {
       //return spot[ col.show ];
       let xLine = line;
       let val = null;
	   
       val = xLine[ col.show ];
       
       if ( col.type==='time' )
    	{
			if ( val )
			{
    	   		return printTimeMMSS(val);
		   	}
    	}
		if ( ! val )
    	{
    		return " - ";
    	}
		if ( col.type==='reach' )
	   	{
	   	   return formatNumber(val/1000);
	   	}
        if ( col.type==='perc' )
        {
   	       return (Math.round( 100 * val)/100)+'%';
   	    }

    	
    	
    	if ( col.boolean )
    	{
    		if ( val )
    		{
    			return "X";
    		}
    		return "";
    	}
    	if ( isNaN( val) )
    	{
    		return val?val:"";
    	}
    	try
    	{
    		
    		return val ? formatNumber( val ) : val;
    	}
    	catch(e) {
    		return "";
    	}
    },
	downloadConf(plcm) { 
   	    	this.pbId = plcm;
   	    	this.pbAction = "downloadConfInternal";
   	    	this.pbTitle = "Creating confirmation";
   	  	    this.showProgressBar = true;
    },
	downloadConfInternal( myId, api )
    {
    	let that = this;
		api.post( opsAPI+"/downloadConf/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.pbId+"/1/"+myId)
	        .then( response => 
	        { 
               
               let downloadResponse = response.data;
			   that.showProgressBar = false;
               that.$refs.pdfView.open(downloadResponse.file, downloadResponse.filename)
           		
        	}).catch(e => {that.showProgressBar = false; showError(  that.$toast, "file download error", e)})

    },
    getData( ) {
    	this.pbAction = "getDataInternal";
    	this.pbInfo = "Loading data";
    	this.showProgressBar = true;
    },
    getDataInternal( myId, api ) 
	{
		let that = this;
		//alert(this.stationId)
		if ( !this.stationId )
		{
			that.showProgressBar = false;
			HTTP.post( pricingAPI+"/getLastHeader/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
        		 .then( response => 
                 {
					let allStations = response.data.channels;
					allStations.sort((a,b)=>a.name.localeCompare(b.name));
					that.pricingHeader = response.data.header;
					if ( that.pricingHeader.definitions)
					{
						that.options = that.pricingHeader.definitions;
						that.selectedDefault = that.pricingHeader.definitions.columns;
						
					}
					
					if ( !that.selectedDefault)
					{
						that.selectedDefault = that.selectedDefaultD;
					}
					that.stations = allStations.map(a => {return {id: a.id, label: a.name, visible: true}});
                    //that.reports = response.data.reports;
					that.tg1 = response.data.tg1;
					that.tg2 = response.data.tg2;
                    that.stationId =  that.pricingHeader.mediaId;
					
					              
                 }).catch(e => {
                        that.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
						that.showProgressBar = false;
                    });
		}
        else
		{
			let req = {	 
						fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
						untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] 
			};
			let inputStruct = { search: this.searchTerm};
			let dataLoad = new Promise(function (resolve, reject) {
				
	       		api.post( pricingAPI+"/findStructureBreaks/"+sessionStorage.tenantId
	       				+"/"+sessionStorage.accessPointId
	       				+"/"+sessionStorage.unitId
	       				+"/"+sessionStorage.userId
						+"/"+that.stationId
						+"/"+req.fromDateISO
	       				+"/"+req.untilDateISO
	       				+"/"+myId
	       				)
	
	        		 .then( response => 
	                 {
						
	                 	that.data = response.data;
	                 	that.dataSave = response.data;
						that.pricingHeader = response.data.header;
						that.tg1 = response.data.tg1;
						that.tg2 = response.data.tg2;
						
						for ( let ci in that.availableColumns)
						{
							let col = that.availableColumns[ci];

							if ( col.pattern )
							{
								if ( col.pattern == 'TG1')
								col.name =  that.tg1?"Ø "+that.tg1.shortname: "Ø ReachTG1";
								if ( col.pattern == 'TG2')
								col.name =  that.tg2?"Ø "+that.tg2.shortname: "Ø ReachTG1";
							}

							
						}
						that.ratecards = response.data.ratecards;
						that.ratecards = that.ratecards.map( p=> { return { id: p.pricegroup, label: p.pricegroup+" ("+formatNumber(p.price/p.duration*30)+")", visible:true}})
						//that.data.weekdays = that.data.grid.map( p=> p.weekdayValue);
						//alert( JSON.stringify(that.data.grid[0]))
						//that.data.weekdays = [...new Set(that.data.weekdays)]
						//that.data.sites.sort( (a,b)=>a.label.localeCompare(b.label));
						
						that.data.grid.sort( (a,b) => a.time-b.time);
						that.types = new Set( that.data.grid.map(p=>p.rateType));
						
						if ( that.pricingHeader.definitions)
						{
							that.options = that.pricingHeader.definitions;
							that.selectedDefault = that.pricingHeader.definitions.columns;
							
						}
						
						if ( (!that.options || !that.options.selectedTypes ) || that.types.length === 1 || !that.options.selectedTypes.length)
						{
							that.options.selectedTypes = [...that.types]
						}
						if ( !that.selectedDefault)
						{
							that.selectedDefault = that.selectedDefaultD;
						}
						that.calcTotals();
						that.fromDate = that.pricingHeader.fromDate;
						that.untilDate = that.pricingHeader.untilDate;
	                 	//alert( JSON.stringify( that.data.stati))
	                 	that.selected = null;
	                 	that.arrangeColumns();
						
	                 	that.ready = true;
						
						that.showProgressBar = false;
						if ( !that.activeWeekday)
						{
							that.activeWeekday = that.data.weekdays[0];
						}
	                    resolve (true);     
	                    
	                 }).catch(e => {
						   console.log(e)
	                	    showError( that.$toast, "get grid data", e);
	                	    that.showProgressBar = false;
	                        reject ();
	                    });
		      });
		}
    },
	calcTotals()
	{
		let that = this;
		that.weekdayTotals = {};
		that.weekdayTotals[0] = {name: "Totals", avgReachTG1: 0, avgReachTG2: 0, cpm: 0, cpmNew: 0, price30: 0, price30New: 0};
		that.weekdayTotals[0].avgReachTG1 = 0;
		that.weekdayTotals[0].avgReachTG2 = 0;
		that.weekdayTotals[0].price30 = 0;
		that.weekdayTotals[0].price30New = 0;
		that.weekdayTotals[0].price30Delta = 0;
		that.weekdayTotals[0].cpmDelta = 0;
		that.weekdayTotals[0].cpmTargetDelta = 0;
		that.weekdayTotals[0].cpmTargetDeltaBefore = 0;
		//alert( JSON.stringify(that.options.selectedTypes))
		for ( let di in that.data.weekdays)
		{
			let wd = that.data.weekdays[di];
			that.weekdayTotals[wd] = {name: "Totals "+wd, avgReachTG1: 0, avgReachTG2: 0, cpm: 0, cpmNew: 0, price30: 0, price30New: 0};
			let list = that.data.grid.filter( p=>p.weekdayValue===wd && that.options.selectedTypes.find(x=>x===p.rateType));
			let reachTotal1 = 0;
			let reachTotal2 = 0;
			let priceTotal = 0;
			let cpmDelta = 0;
			let cpmTargetDelta = 0;
			let cpmTargetDeltaBefore = 0;
			let priceTotalNew = 0;
			let priceDeltaNew = 0;
			
			for ( let gi in list)
			{
				let bg = list[gi];
				reachTotal1 += bg.avgReachTG1;
				reachTotal2 += bg.avgReachTG2;
				priceTotal += bg.price30;
				priceTotalNew += bg.price30New;
				priceDeltaNew += bg.price30Delta;
				cpmDelta += bg.cpmDelta;
				cpmTargetDelta += bg.cpmTargetDelta;
				cpmTargetDeltaBefore += bg.cpmTargetDeltaBefore;
			}
			that.weekdayTotals[wd].avgReachTG1 = reachTotal1;
			that.weekdayTotals[wd].avgReachTG2 = reachTotal2;
			that.weekdayTotals[wd].price30 = priceTotal;
			that.weekdayTotals[wd].price30New = priceTotalNew;
			that.weekdayTotals[wd].price30Delta = priceDeltaNew;
			that.weekdayTotals[wd].cpmDelta = cpmDelta;
			that.weekdayTotals[wd].cpmTargetDelta = cpmTargetDelta;
			that.weekdayTotals[wd].cpmTargetDeltaBefore = cpmTargetDeltaBefore;
			
			that.weekdayTotals[wd].cpm = 1000*1000*priceTotal / reachTotal1
			that.weekdayTotals[wd].cpmNew = 1000*1000*priceTotalNew / reachTotal1
			
			that.weekdayTotals[0].avgReachTG1 += reachTotal1;
			that.weekdayTotals[0].avgReachTG2 += reachTotal2;
			that.weekdayTotals[0].price30 += priceTotal;
			that.weekdayTotals[0].price30New += priceTotalNew;
			that.weekdayTotals[0].price30Delta += priceDeltaNew;
			that.weekdayTotals[0].cpmDelta += cpmDelta;
			that.weekdayTotals[0].cpmTargetDelta += cpmTargetDelta;
			that.weekdayTotals[0].cpmTargetDeltaBefore += cpmTargetDeltaBefore;
			
			that.weekdayTotals[0].cpm = 1000*1000*that.weekdayTotals[0].price30 / that.weekdayTotals[0].avgReachTG1
			that.weekdayTotals[0].cpmNew = 1000*1000*that.weekdayTotals[0].price30New / that.weekdayTotals[0].avgReachTG1
		}
	},
	calcData( ) {
    	this.pbAction = "calcDataInternal";
    	this.pbInfo = "Loading data";
    	this.showProgressBar = true;
    },
    calcDataInternal( myId, api ) 
	{
		let that = this;
		//alert(this.stationId)
		
			let req = {	 
						fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
						untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] 
			};
			let inputStruct = { search: this.searchTerm};
			let dataLoad = new Promise(function (resolve, reject) {
				
       		api.post( pricingAPI+"/calcStructureBreaks/"+sessionStorage.tenantId
       				+"/"+sessionStorage.accessPointId
       				+"/"+sessionStorage.unitId
       				+"/"+sessionStorage.userId
					+"/"+that.stationId
					+"/"+req.fromDateISO
       				+"/"+req.untilDateISO
       				+"/"+myId, that.pricingHeader
       				)

        		 .then( response => 
                 {
					
                 	that.data = response.data;
                 	that.dataSave = response.data;
					that.pricingHeader = response.data.header;
					that.ratecards = response.data.ratecards;
					that.ratecards = that.ratecards.map( p=> { return { id: p.pricegroup, label: p.pricegroup+" ("+formatNumber(p.price/p.duration*30)+")", visible:true}})
					//that.data.weekdays = that.data.grid.map( p=> p.weekdayValue);
					//alert( JSON.stringify(that.data.grid[0]))
					//that.data.weekdays = [...new Set(that.data.weekdays)]
					//that.data.sites.sort( (a,b)=>a.label.localeCompare(b.label))
					
					if ( that.pricingHeader.definitions)
					{
						that.options = that.pricingHeader.definitions;
						that.selectedDefault = that.pricingHeader.definitions.columns;
						if ( !that.selectedDefault)
						{
							that.selectedDefault = that.selectedDefaultD;
						}
					}
					
					that.data.grid.sort( (a,b) => a.time-b.time);
                 	//alert( JSON.stringify( that.data.stati))
                 	that.selected = null;
                 	that.arrangeColumns();
					
					that.calcTotals();
					
                 	that.ready = true;
					
					that.showProgressBar = false;
					if ( !that.activeWeekday)
					{
						that.activeWeekday = that.data.weekdays[0];
					}
                    resolve (true);     
                    
                 }).catch(e => {
					   console.log(e)
                	    showError( that.$toast, "get grid data", e);
                	    that.showProgressBar = false;
                        reject ();
                    });
	      });
		
    },	
	lockData( flag ) {
    	this.pbAction = "lockDataInternal";
    	this.pbInfo = flag ? "Locking breaks" : "Unlocking breaks";
		this.pbLock = flag;
    	this.showProgressBar = true;
    },
    lockDataInternal( myId, api ) 
	{
		let that = this;
		//alert(this.stationId)
		
			let req = {	 
						fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
						untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] 
			};
			let inputStruct = { search: this.searchTerm};
			let dataLoad = new Promise(function (resolve, reject) {
				
       		api.post( pricingAPI+"/lockStructureBreaks/"+sessionStorage.tenantId
       				+"/"+sessionStorage.accessPointId
       				+"/"+sessionStorage.unitId
       				+"/"+sessionStorage.userId
					+"/"+that.stationId
					+"/"+req.fromDateISO
       				+"/"+req.untilDateISO
					+"/"+that.pbLock
       				+"/"+myId, that.pricingHeader
       				)

        		 .then( response => 
                 {
					
                 	that.data = response.data;
                 	that.dataSave = response.data;
					that.pricingHeader = response.data.header;
					that.ratecards = response.data.ratecards;
					that.ratecards = that.ratecards.map( p=> { return { id: p.pricegroup, label: p.pricegroup+" ("+formatNumber(p.price/p.duration*30)+")", visible:true}})
					//that.data.weekdays = that.data.grid.map( p=> p.weekdayValue);
					//alert( JSON.stringify(that.data.grid[0]))
					//that.data.weekdays = [...new Set(that.data.weekdays)]
					//that.data.sites.sort( (a,b)=>a.label.localeCompare(b.label))
					
					if ( that.pricingHeader.definitions)
					{
						that.options = that.pricingHeader.definitions;
						that.selectedDefault = that.pricingHeader.definitions.columns;
						if ( !that.selectedDefault)
						{
							that.selectedDefault = that.selectedDefaultD;
						}
					}
					
					that.data.grid.sort( (a,b) => a.time-b.time);
                 	//alert( JSON.stringify( that.data.stati))
                 	that.selected = null;
                 	that.arrangeColumns();
					
					that.calcTotals();
					
                 	that.ready = true;
					
					that.showProgressBar = false;
					if ( !that.activeWeekday)
					{
						that.activeWeekday = that.data.weekdays[0];
					}
                    resolve (true);     
                    
                 }).catch(e => {
					   console.log(e)
                	    showError( that.$toast, "get grid data", e);
                	    that.showProgressBar = false;
                        reject ();
                    });
	      });
		
    },	
	
    goBack()
    {
    	this.data = [];
    	this.selectedDefault = [...this.selectedSave];
    	this.selected = null;
        this.arrangeColumns();
    	for ( let i in this.dataSave)
    	{
    		this.data.push( this.dataSave[i]);
    	}

    },
    getOrderBy( col )
    {
    	for ( var i in this.orderBy)
    	{
    		let iCol = this.orderBy[i];
    		if ( iCol.show === col.show )
    		{
    		    if ( iCol.sorting > 0 )
    		    {
    				return "A"+(parseInt(i)+1);
    			}
    			return "D"+(parseInt(i)+1);
    		}
    	}
    	return "";
    },
   setOrderBy( col )
    {
       if ( col.type === 'icon' || col.type === 'progress')
       {
    	   return;
       }
       var add = true;
    	for ( var i in this.orderBy)
    	{
    		let iCol = this.orderBy[i];
    		if ( iCol.show === col.show )
    		{
    		    if ( col.sorting === 1 )
    		    {
    		    	col.sorting = -1;
    		    }
    		    else
    		    {
    				this.orderBy.splice( i, 1);
    				col.sorting = 0;
    			}
    			add = false;
    			break;
    		}
    	}
    	if ( add )
    	{
    		this.orderBy.push( col );
    		col.sorting = 1;
    	}
    	//this.doOrderBy();
    	this.$forceUpdate();
    	
    },
	arrangeColumns()
	{
		this.availableColumns = this.availableColumns.sort((a,b)=>a.name.localeCompare(b.name));
	    this.selectedColumns = new Array();
	    //alert( JSON.stringify( this.selectedIN))
	    if ( this.selectedIN)
	    {
	    	try
	    	{
	    		this.selected = this.selectedIN.split(',');
	    		//alert( this.selected)
	        } catch (e)
	        {
	        	//alert(JSON.stringify(e))
	        	this.selected = [];
	        }
	    	
	    }
	    //alert( JSON.stringify( this.selected))
	    if ( ! this.selected || ! this.selected.length )
	    {
	    	this.selected = [];
	    	
	    	for ( let i in this.selectedDefault )
			{
				let col = this.availableColumns.find( p=> p.id===this.selectedDefault[i])
				this.selected.push( col.id);
			}
	    }
	    //console.log(JSON.stringify(this.selected))
		for ( let i in this.selected )
		{
			let id = parseInt(this.selected[i]);
			for ( var j in this.availableColumns )
			{
				let column = this.availableColumns[j];
				if ( column.id === id )
				{
					this.selectedColumns.push( column );
					break;
				}
			}
	    }
	    this.calcWidths( false);
	},
	calcWidths( withSaving)
	{
	    let wTot = 0;
		var columns = "";
		for ( var i in this.selectedColumns )
     	{
     		wTot += this.selectedColumns[ i].w;
     		if ( columns )
     		{
     			columns += "," + this.selectedColumns[ i].id;
     		}
     		else
     		{
     			columns = this.selectedColumns[ i].id;
     		}
     	}
		this.widthCalculated = wTot;
		
	},


  },
  	
  beforeDestroy() {
	 this.saveHeader();

  },
  created() {
		let today = new Date();
		this.fromDate = new Date();
		
		this.fromDate = new Date( isoDate(today.getFullYear(), today.getMonth()+2, 1));
		this.untilDate = new Date( isoDate(today.getFullYear(), 12, 31));
		//this.untilDate.setDate(this.untilDate.getDate() + 36);
		this.fromDate = isoDate(today.getFullYear(), today.getMonth()+2, 1)
		this.untilDate = isoDate(today.getFullYear(), 12, 31)
		this.arrangeColumns();
		this.calcWidths();
		this.getData();
		this.dateRef=momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0];
		initAppMode();
	    

  },
  watch: {
  	fromDate: function() { 
		this.untilDate = isoDate(this.fromDate.getFullYear(), 12, 31) 
		this.dateRef = momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0]; 
	},
  	untilDate: function() {  },
  	stationId: function() {
		if ( this.pricingHeader && this.pricingHeader.mediaId)
		{
			this.saveHeader();
		}
		if ( this.stationId )
		{
			this.getData();
		}
	}
  },
  updated() {
  }
}
</script>
<style lang="scss" scoped>
 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
 .myButtonCalc {
	float: right;
	width: 40pt;
 }
 .myButtonLock {
 float: left;
 width: 40pt;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11pt !important; 
  font-weight: bold; 
  width: 100% !important; 
  height: 18pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  }
  
.rt { 
    

 }  
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  overflow: hidden; 
  text-overflow: ellipsis;
}
.centered {
  justify-content: center;
  text-align: center;
}
.right {
  justify-content: right;
  text-align: right;
}
.left {
  justify-content: left;
  text-align: left;
}
.floatLeft {
    display: inline-flex;
    float: left;
}
.reqLineBG {
  background-color: #e8f0e8 !important; 
}
.reqLineBGDM {
  background-color: #aca !important; 
  color: #000 !important;
}
.ltd {
  padding-left: 1pt;
  padding-right: 1pt;
  overflow-x: hidden;
  font-size: 11px;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.reqDetail { 
  display: flex;
  align-items: center;
  font-size: 8pt;  
  width: 100% !important; 
  height: 20pt; 
  padding-left: 2pt;
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  overflow: hidden;
  cursor:pointer;
  //background-color: #aaa; 
}
.reqDetail2 { 
  padding-top: 3pt;
  cursor:pointer;
  
}
.reqDetailNB { 
  display: flex;
  align-items: center;
  font-size: 8pt;  
  width: 100% !important; 
  height: 20pt; 
  overflow: hidden;
  cursor:pointer;
  //background-color: #aaa; 
}
.mySwitchButton {
	width: 30% !important;
    padding: 0px 0px;
    outline: none;
	justify-content: left;
    border-radius: 4px 4px 4px 4px;
    height: 20pt;
    font-size: 7pt;
    background-color: #fffaff;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 0pt;
    margin-right: 0pt;
    margin-bottom: 0pt;
}
.mySwitchSelected {
	width: 30% !important;
    padding: 0px 0px;
    outline: none;
	justify-content: left;
    border-radius: 4px 4px 4px 4px;
    height: 20pt;
    font-size: 7pt;
    background-color: #fffaff;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 0pt;
    margin-right: 0pt;
    margin-bottom: 0pt;
}
.myTab {
	width: 80pt;
    padding: 5px 11px;
    outline: none;
	font-weight: bold;
    border-radius: 4px 12px 0 0;
    height: 20pt;
    font-size: 7pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 0pt;
    margin-bottom: 0pt;
}
.myTabActive {
	//width: 18%;
    padding: 3px 10px;
    background-color: #aaf;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
} 
.myBNActive {
	//width: 18%;
    background-color: #888 !important;
    color: #fff !important;
} 
.mySelectButton {
	width: 60% !important;
    padding: 5px 1px;
    outline: none;
	justify-content: left;
    border-radius: 4px 4px 4px 4px;
    height: 20pt;
    font-size: 7pt;
    background-color: #f8f8ff;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 0pt;
    margin-bottom: 0pt;
}
.selected {
	width: 62% !important;
	background-color: rgb(0, 128, 128);
	color: rgb(0, 255, 255);
}
.myButtonSmall {
	width: 20pt;
	    padding: 1px 1px;
	    outline: none;
		float: left;
		justify-content: left;
	    border-radius: 4px 4px 4px 4px;
	    height: 18pt;
	    font-size: 7pt;
	    
	    border: none;
	    
	    margin-top: 1pt;
	    margin-right: 0pt;
	    margin-bottom: 1pt;
}
</style>

